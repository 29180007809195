import React from "react";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
{/* Topbar Start */}
<div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
    <div className="row gx-0">
      <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
        <div className="d-inline-flex align-items-center">
          <small className="py-2"><i className="far fa-clock text-primary me-2" />Opening Hours: Mon - Sat : 09.00 am - 6.00 pm, Sunday Closed </small>
        </div>
      </div>
      <div className="col-md-6 text-center text-lg-end">
        <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
          <div className="me-3 pe-3 border-end py-2">
            <p className="m-0"><i className="fa fa-envelope-open me-2" />sunflagglobalhealthcares@gmail.com</p>
          </div>
          <div className="py-2">
            <p className="m-0"><i className="fa fa-phone-alt me-2" />+91 9991547708</p>
      
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}

      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
        <NavLink to="/" className="navbar-brand p-0">
          <h1 className="m-0 text-primary">
            Sunflag
          </h1>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <NavLink to="/" activeStyle={{ color:"#06A3DA" }} className="nav-item nav-link">
              Home
            </NavLink>
            <NavLink to="/about" activeStyle={{ color:"#06A3DA" }} className="nav-item nav-link">
              About
            </NavLink>
            <NavLink to="/service" activeStyle={{ color:"#06A3DA" }} className="nav-item nav-link">
              Services
            </NavLink>
            <div className="nav-item dropdown">
              <Link to="" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <NavLink to="/product" activeStyle={{ color:"#06A3DA" }} className="dropdown-item">
                  Our Products
                </NavLink>
                <NavLink to="/testimonial" activeStyle={{ color:"#06A3DA" }} className="dropdown-item">
                  Testimonial
                </NavLink>
                <NavLink to="/appointment" activeStyle={{ color:"#06A3DA" }} className="dropdown-item">
                  Appointment
                </NavLink>
                <NavLink to="/offers" activeStyle={{ color:"#06A3DA" }} className="dropdown-item">
                Our Offers
                </NavLink>
              </div>
            </div>
            <NavLink to="/contact" activeStyle={{ color:"#06A3DA" }} className="nav-item nav-link">
              Contact
            </NavLink>
          </div>
          <button
            type="button" className="btn text-dark"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            <i className="fa fa-search" />
          </button>
          <NavLink to="/appointment" activeStyle={{ color:"#06A3DA" }} className="btn btn-primary py-2 px-4 ms-3">
            Appointment
          </NavLink>
        </div>
      </nav>
      {/* Navbar End */}
        {/* Full Screen Search Start */}
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content" style={{background: 'rgba(9, 30, 62, .7)'}}>
        <div className="modal-header border-0">
          <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{maxWidth: 600}}>
            <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Madical Equipments & Instuments Search" />
            <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  </>
  );
};

export default Navbar;
