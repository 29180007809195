import React from 'react'
import About from './About'
import TestimonialCarousel from './TestimonialCarousel'
import { Link } from 'react-router-dom'



const Home = () => {
  return (
   <>
  <div>
  {/* Spinner Start */}
  
  {/* Spinner End */}
  {/* Topbar Start */}
  
  {/* Topbar End */}
  {/* Navbar Start */}
  
  {/* Navbar End */}
  {/* Full Screen Search Start */}
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content" style={{background: 'rgba(9, 30, 62, .7)'}}>
        <div className="modal-header border-0">
          <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{maxWidth: 600}}>
            <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
            <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  {/* Carousel Start */}
  <div className="container-fluid p-0">
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Make Your Businesses</h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">Take The Best Quality Medical All Equipment Products</h1>
              <Link to="/appointment" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Appointment</Link>
              <Link to="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img className="w-100" src="img/carousel-2.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Take Your Products</h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">Take The Best Quality Medical All Instument Products</h1>
              <Link to="/appointment" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Appointment</Link>
              <Link to="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img className="w-100" src="img/carousel-3.jpeg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Make Your Services</h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">Take The Best Quality Medical All Services</h1>
              <Link to="/appointment" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Appointment</Link>
              <Link to="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  {/* Carousel End */}
  {/* Banner Start */}
  <div className="container-fluid banner mb-5">
    <div className="container">
      <div className="row gx-0">
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
          <div className="bg-primary d-flex flex-column p-5" style={{height: 300}}>
            <h3 className="text-white mb-3">Opening Hours</h3>
            <div className="d-flex justify-content-between text-white mb-3">
              <h6 className="text-white mb-0">Mon - Fri</h6>
              <p className="mb-0"> 09:00am - 6:00pm</p>
            </div>
            <div className="d-flex justify-content-between text-white mb-3">
              <h6 className="text-white mb-0">Saturday</h6>
              <p className="mb-0"> 09:00am - 6:00pm</p>
            </div>
            <div className="d-flex justify-content-between text-white mb-3">
              <h6 className="text-white mb-0">Sunday</h6>
              <p className="mb-0"> Holiday</p>
            </div>
            <a className="btn btn-light" href>Appointment</a>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
          <div className="bg-dark d-flex flex-column p-5" style={{height: 300}}>
            <h3 className="text-white mb-3">Search All Equipments</h3>
            <div className="date mb-3" id="date" data-target-input="nearest">
              <input type="text" className="form-control bg-light border-0 datetimepicker-input" placeholder="Appointment Date" data-target="#date" data-toggle="datetimepicker" style={{height: 40}} />
            </div>
            <select className="form-select bg-light border-0 mb-3" style={{height: 40}}>
              <option selected>Select A Service</option>
              <option value={1}>Service 1</option>
              <option value={2}>Service 2</option>
              <option value={3}>Service 3</option>
            </select>
            <a className="btn btn-light" href>Search Equipments</a>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
          <div className="bg-secondary d-flex flex-column p-5" style={{height: 300}}>
            <h3 className="text-white mb-3">Make Appointment</h3>
            <p className="text-white">Ipsum erat ipsum dolor clita rebum no rebum dolores labore.</p>
            <h6 className="text-white mb-0">sunflagglobalhealthcares@gmail.com</h6>
            <h4 className="text-white mb-0">+91 9991547708, 8814056486</h4>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Banner Start */}
  {/* About Start */}
   <About />
  {/* About End */}
  {/* Appointment Start */}
  <div className="container-fluid bg-primary bg-appointment my-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-6 py-5">
          <div className="py-5">
            <h1 className="display-5 text-white mb-4">We Are A Certified and Award Winning Madical Businesses You Can Trust</h1>
            <p className="text-white mb-0">Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit. Sea dolore sanctus sed et. Takimata takimata sanctus sed.</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="appointment-form h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
            <h1 className="text-white mb-4">Make Appointment</h1>
            <form>
              <div className="row g-3">
                <div className="col-12 col-sm-6">
                  <select className="form-select bg-light border-0" style={{height: 55}}>
                    <option selected>Select A Service</option>
                    <option value={1}>Service 1</option>
                    <option value={2}>Service 2</option>
                    <option value={3}>Service 3</option>
                  </select>
                </div>
                <div className="col-12 col-sm-6">
                  <select className="form-select bg-light border-0" style={{height: 55}}>
                    <option selected>Select Service</option>
                    <option value={1}>Doctor 1</option>
                    <option value={2}>Doctor 2</option>
                    <option value={3}>Doctor 3</option>
                  </select>
                </div>
                <div className="col-12 col-sm-6">
                  <input type="text" className="form-control bg-light border-0" placeholder="Your Name" style={{height: 55}} />
                </div>
                <div className="col-12 col-sm-6">
                  <input type="email" className="form-control bg-light border-0" placeholder="Your Email" style={{height: 55}} />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="date" id="date1" data-target-input="nearest">
                    <input type="text" className="form-control bg-light border-0 datetimepicker-input" placeholder="Appointment Date" data-target="#date1" data-toggle="datetimepicker" style={{height: 55}} />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="time" id="time1" data-target-input="nearest">
                    <input type="text" className="form-control bg-light border-0 datetimepicker-input" placeholder="Appointment Time" data-target="#time1" data-toggle="datetimepicker" style={{height: 55}} />
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-dark w-100 py-3" type="submit">Make Appointment</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Appointment End */}
  {/* Service Start */}
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="section-title bg-light rounded h-100 p-5">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Services</h5>
            <h1 className="display-6 mb-4">We Offer The Best Quality of Equipments</h1>
            <Link to="/service" className="btn btn-primary py-3 px-5">More Services...</Link>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-1.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">All ICU EQUIPMENTS</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
       
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-2.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">All NICU EQUIPMENTS </h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
                 
      </div>
    </div>
  </div>
  {/* Service End */}
  {/* Offer Start */}
  <div className="container-fluid bg-offer my-5 py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-7 wow zoomIn" data-wow-delay="0.6s">
          <div className="offer-text text-center rounded p-5">
            <h1 className="display-5 text-white">Save 10% On Your First Madical Equipments & Instuments</h1>
            <p className="text-white mb-4">Eirmod sed tempor lorem ut dolores sit kasd ipsum. Dolor ea et dolore et at sea ea at dolor justo ipsum duo rebum sea. Eos vero eos vero ea et dolore eirmod diam duo lorem magna sit dolore sed et.</p>
            <Link href="/appointment" className="btn btn-dark py-3 px-5">Appointment</Link>
            <a href className="btn btn-light py-3 px-5">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Offer End */}

  {/* Testimonial Start */}
  
  <div className="container-fluid bg-primary bg-testimonial py-5 mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{marginTop: 90}}>
    <div className="container py-5">
      <div className="row justify-content-center">
  <TestimonialCarousel/>
      </div>
    </div>
  </div>

  {/* Testimonial End */}
  {/* Products Start */}
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="section-title bg-light rounded h-100 p-5">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">Our All Equipments</h5>
            <h1 className="display-6 mb-4">Visit Our Certified All Products</h1>
            <Link to="/product" className="btn btn-primary py-3 px-5">More Products...</Link>
          </div>
        </div>

        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-1.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
               <h4 className="mb-2">Massimo Pulse Oximeter</h4>  
               <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-2.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">5 Para Patient Monitor H8</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-3.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
               
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE Versamed Ventilator iVent 201</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-4.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Zoll M Series Defibrillator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-5.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Philips MP70 MultiPara Patient Monitor</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  {/* Products End */}
  {/* Newsletter Start */}
  
  {/* Newsletter End */}
  {/* Footer Start */}
  
  {/* Footer End */}
  {/* Back to Top */}
  
</div>

   </>
  )
}

export default Home