import React from 'react'
import { Link } from 'react-router-dom'

const Offers = () => {
  return (
    <>
    {/* Hero Start */}
    <div className="container-fluid bg-primary py-3 hero-header mb-4">
    <div className="">
      <div className="text-center">
        <h3 className="text-white animated zoomIn">Our Offers</h3>
       </div>
    </div>
  </div>
  
  {/* Hero End */}
       {/* Offer Start */}
  <div className="container-fluid bg-offer my-5 py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-7 wow zoomIn" data-wow-delay="0.6s">
          <div className="offer-text text-center rounded p-5">
            <h1 className="display-5 text-white">Save 10% On Your First Madical Equipments & Instuments</h1>
            <p className="text-white mb-4">Eirmod sed tempor lorem ut dolores sit kasd ipsum. Dolor ea et dolore et at sea ea at dolor justo ipsum duo rebum sea. Eos vero eos vero ea et dolore eirmod diam duo lorem magna sit dolore sed et.</p>
            <Link href="/appointment" className="btn btn-dark py-3 px-5">Appointment</Link>
            <a href className="btn btn-light py-3 px-5">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Offer End */}
    </>
  )
}

export default Offers