import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Navbar from "./Components/Navbar";
import Services from "./Components/Services";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Dentist from "./Components/Dentist";
import Appointment from "./Components/Appointment";
import Testimonial from "./Components/Testimonial";
import Offers from "./Components/Offers";
import Item1 from "./Components/SubComponents/Item1";


function App() {
  return (
    <>
    <Navbar />
    <Routes>
    
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/service" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/product" element={<Dentist />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/testimonial" element={<Testimonial />} />
      <Route path="/offers" element={<Offers />} />

      <Route path="/item1" element={<Item1 />} />

    </Routes>
    <Footer />
    </>
  );
}

export default App;
