import React, { useEffect, useState } from 'react';


const testimonials = [
  {
    id: 1,
    name: 'Jane Smith',
    image: 'testimonial-1.jpg',
    comment: 'I had an amazing experience.'
  },
  {
    id: 2,
    name: 'jenny ',
    image: 'testimonial-2.jpg',
    comment: 'I\'m grateful for their expertise.'
  },
  // Add more testimonials as needed
];

const TestimonialCarousel = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    const handleNext = () => {
      setCurrentTestimonialIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
  
    const handlePrev = () => {
      setCurrentTestimonialIndex(prevIndex => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };
  
  
    useEffect(()=>{
      const timer = setTimeout(()=>{
       return handleNext();
     },5000)
     return ()=>{
  clearTimeout(timer)
     }
   },[currentTestimonialIndex])


  return (
    <>
    
<div>

  {/* Testimonial Start */}

        <div className="testimonial-carousel">
      <div className="testimonial">
        <img src={`${process.env.PUBLIC_URL}/img/${testimonials[currentTestimonialIndex].image}`} alt={testimonials[currentTestimonialIndex].name} />
        <h3>{testimonials[currentTestimonialIndex].name}</h3>
        <p>{testimonials[currentTestimonialIndex].comment}</p>
      </div>

    </div>
</div>


     

    </>
  )
}

export default TestimonialCarousel;