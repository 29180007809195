import React from 'react'
import { Link, useParams } from 'react-router-dom'


// const url = "https://chiranjeevibhav.com/about-us-2/"

const Dentist = () => {
{/*
  const handleClick = (url)=> {
    const whatsappMassage = `Hello, I'm intrested in this products. ${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919818359852&text=${encodeURIComponent(whatsappMassage)}`;
    window.open(whatsappUrl, "_blank");
  };

   onClick={()=>handleClick(url)} 
*/}

  return (
    <>
<div>
  {/* Hero Start */}
  <div className="container-fluid bg-primary py-3 hero-header mb-4">
    <div className="">
      <div className="text-center">
        <h3 className="text-white animated zoomIn">Our Products [Equipments & Instuments]</h3>
       </div>
    </div>
  </div>
  {/* Hero End */}
  {/* Product Start */}
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="section-title bg-light rounded h-100 p-5">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Products</h5>
            <h1 className="display-6 mb-4">Visit Our Certified All Products</h1>
            <Link to="/contact" className="btn btn-primary py-3 px-5">Appointment</Link>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-1.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
               <h4 className="mb-2">Massimo Pulse Oximeter</h4>  
               <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-2.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">5 Para Patient Monitor H8</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-3.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
               
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE Versamed Ventilator iVent 201</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-4.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Zoll M Series Defibrillator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-5.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Philips MP70 MultiPara Patient Monitor</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-6.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">3 Channel ECG machine</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-7.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Fabian HFO INFANT Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-8.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">3 Channel ECG machine</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-9.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">IABP machine CS100,300</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-10.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Akas Infusion Pump</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-11.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE Engstrom Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-12.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Bird Meditech Inspiration Baby Warmer</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-13.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Bird Meditech INNOVA Baby Warmer</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-14.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Bird Meditech Phototherapy 15 LED</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-15.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">VentMed Bipap Machine</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-16.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Beyond Bipap Machine</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-17.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Philips MP50 MultiPara Patient Monitor</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-18.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">LTV 950,1000,1150,1200 Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-19.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE Versamed Ventilator I vent 201</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-20.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">5 Para Patient Monitor XM750</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-21.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Nellcor 595,600X Pulse Oximeter</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-22.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Zoll M Series Defibrillator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-23.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Drager Babylog 8000 Plus Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-24.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Nellcor Covidien Table Top Pulse Oximeter</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-25.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">12 Channel ECG machine</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-26.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Akas syringe pump RX</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-27.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Fisher & Paykel Airvo 2</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-28.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Akas Syringe Pump SYRU-4004</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-29.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE SLE 5000 Infant Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-30.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">MR850 Humidifier</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-31.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Bubble CPAP machine</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-32.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Nellcor Covidien Touch Pulse Oximeter</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-33.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Drager Babylog VN500 Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-34.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Respiratory Humidifier</h4>
              <p className="text-primary mb-0">NEW PRODUCT</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-35.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">GE Anesthesia Machine</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-36.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Drager Evita XL Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-37.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Drager Evita 4 Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/product-38.png" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Nippy 3 Bipap Plus Ventilator</h4>
              <p className="text-primary mb-0">PRE-OWNED</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  {/* Product End */}
</div>

    </>
  )
}

export default Dentist