import React from 'react'
import { Link } from 'react-router-dom'

const Item1 = () => {
  return (
    <>
    {/* Hero Start */}
    <div className="container-fluid bg-primary py-3 hero-header mb-4">
    <div className="">
      <div className="text-center">
        <h3 className="text-white animated zoomIn">Item - 1</h3>
       </div>
    </div>
  </div>
  {/* Hero End */}
  {/* Item Start */}
     <div className=" wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/team-1.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Medical Disposables</h4>
              <p className="text-primary mb-0">Equipments</p>
              <p className="text-primary mb-0">Equipments</p>
              <p className="text-primary mb-0">Equipments</p>
            </div>
          </div>
        </div>
        {/* Item End */}
    </>
  )
}

export default Item1;