import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
     {/* Newsletter Start */}
  <div className="container-fluid position-relative pt-5 wow fadeInUp" data-wow-delay="0.1s" style={{zIndex: 1}}>
    <div className="container">
      <div className="bg-primary p-5">
        <form className="mx-auto" style={{maxWidth: 600}}>
          <div className="input-group">
            <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
            <button className="btn btn-dark px-4">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Newsletter End */}
  {/* Footer Start */}
  <div className="container-fluid bg-dark text-light py-5 wow fadeInUp" data-wow-delay="0.3s" style={{marginTop: '-75px'}}>
    <div className="container pt-5">
      <div className="row g-5 pt-4">
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Quick Links</h3>
          <div className="d-flex flex-column justify-content-start">
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Home</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />About Us</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Our Services</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</Link>
            <Link className="text-light" to=""><i className="bi bi-arrow-right text-primary me-2" />Contact Us</Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Popular Links</h3>
          <div className="d-flex flex-column justify-content-start">
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Home</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />About Us</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Our Services</Link>
            <Link className="text-light mb-2" to=""><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</Link>
            <Link className="text-light" to=""><i className="bi bi-arrow-right text-primary me-2" />Contact Us</Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Get In Touch</h3>
          <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2" />H.No.12, Sawan Park, New Model Town, Near Gurudwara, Panipat-132103</p>
          <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2" />sunflagglobalhealthcares@gmail.com</p>
          <p className="mb-0"><i className="bi bi-telephone text-primary me-2" />+91 9991547708, 8814056486</p>
        </div>
        <div className="col-lg-3 col-md-6">
          <h3 className="text-white mb-4">Follow Us</h3>
          <div className="d-flex">
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-twitter fw-normal" /></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-facebook-f fw-normal" /></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-linkedin-in fw-normal" /></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-instagram fw-normal" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid text-light py-4" style={{background: '#051225'}}>
    <div className="container">
      <div className="row g-0">
        <div className="col-md-6 text-center text-md-start">
      
        </div>
        <div className="col-md-6 text-center text-md-end">
          <p className="mb-0">Website Developed by: <Link className="text-white border-bottom" to="https://Chiranjeevibhav.com">Chiranjeevi Bhav</Link></p>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Back to Top */}
  <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up" /></a>

    </>
  )
}

export default Footer