import React from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
  return (
    <>
    
<div>
  {/* Hero Start */}
  <div className="container-fluid bg-primary py-3 hero-header mb-4">
    <div className="">
      <div className="text-center">
        <h3 className="text-white animated zoomIn">Our Services</h3>
       </div>
    </div>
  </div>
  
  {/* Hero End */}
  {/* Service Start */}
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
          <div className="section-title bg-light rounded h-100 p-5">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Services</h5>
            <h1 className="display-6 mb-4">We Offer The Best Quality of Madical Equipments</h1>
            <Link href="/appointment" className="btn btn-primary py-3 px-5">Appointment</Link>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-1.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">All ICU EQUIPMENTS</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
       
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-2.jpg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">All NICU EQUIPMENTS</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-3.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">MORE SERVICES</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-4.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">Calibration Of All Medical Devices</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="position-relative rounded-top" style={{zIndex: 1}}>
              <img className="img-fluid rounded-top w-100" src="img/service-5.jpeg" alt />
              <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
              
              </div>
            </div>
            <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
              <h4 className="mb-2">All Medical Equipments Repairing In Component Level</h4>
              <p className="text-primary mb-0">Medical Equipments</p>
            </div>
          </div>
        </div>
 
     
                
        
      </div>
    </div>
  </div>
  {/* Service End */}
</div>

    </>
  )
}

export default Services