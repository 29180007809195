import React from 'react'
import TestimonialCarousel from './TestimonialCarousel'
import { Link } from 'react-router-dom'

const Testimonial = () => {
  return (
    <>
    
<div>
  {/* Hero Start */}
  <div className="container-fluid bg-primary py-3 hero-header mb-4">
    <div className="">
      <div className="text-center">
        <h3 className="text-white animated zoomIn">Testimonial</h3>
       </div>
    </div>
  </div>
  {/* Hero End */}
  {/* Testimonial Start */}
  <div className="container-fluid bg-primary bg-testimonial py-5 mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{marginTop: 90}}>
    <div className="container py-5">
      <div className="row justify-content-center">
  <TestimonialCarousel/>
      </div>
    </div>
  </div>
  {/* Testimonial End */}
</div>

    </>
  )
}

export default Testimonial